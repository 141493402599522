<template>
  <div class="container">
    <a href="https://jiin.love/">
      <img class="logo" src="/icon/logo-sm.png" alt="모두의지인" />
    </a>
    <h1>결혼 능력치 테스트💌</h1>
    <h2>
      나의 숨은 결혼 스타일과 능력치는
      <br />얼마나 될까?
    </h2>
    <section class="img">
      <img :src="`/result/entp.png`" alt="entp" />
    </section>
    <Button @confirm="startTest">결혼 능력치 알아보기🎉</Button>
    <Share />
  </div>
</template>

<script>
import Button from "@/components/Button";
import Share from "@/components/Share";
export default {
  name: "Home",
  components: {
    Button,
    Share
  },
  methods: {
    startTest() {
      this.$router.push({
        name: "Test"
      });
    }
  }
};
</script>
<style lang="scss" sceopd>
h2 {
  margin-top: 1.2em;
}
section.img {
  height: auto;
  margin: 1em auto 3em;
  img {
    width: 60%;
  }
}
</style>